<template>
<section class="why-section">
	<div class="floated-icon left"><img src="/assets/images/resource/stones-left.svg" alt="" title=""></div>
	<div class="floated-icon right"><img src="/assets/images/resource/stones-right.svg" alt="" title=""></div>
	<div class="auto-container">

		<div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500"><p><span>Why Choose CIS</span></p><h3>CISを選ぶ理由</h3></div>

		<div class="row clearfix">
			<div class="left-col col-xl-3 col-lg-4 col-md-6 col-sm-12">
				<div class="inner">
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-1.svg" alt=""></div>
							<h4>システム・サービス提供</h4>
							<div class="text">お客様にとって、システム・サービス提供の「CIS」となる</div>
						</div>
					</div>
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-2.svg" alt=""></div>
							<h4>社員にとって生活・人生</h4>
							<div class="text">社員にとって生活・人生の「CIS」となる</div>
						</div>
					</div>
					<div class="why-block">
						<div class="inner-box"  data-aos="fade-right" data-aos-duration="500">
							<div class="icon-box"><img src="/assets/images/resource/icon-6.svg" alt=""></div>
							<h4>ICTソリューション事業</h4>
							<div class="text">各業種・業態の国内外のICTソリューションの展開により、お客様に安心と感動を与えます</div>
						</div>
					</div>
				</div>
			</div>

			<div class="right-col col-xl-3 col-lg-4 col-md-6 col-sm-12">
				<div class="inner">
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-4.svg" alt=""></div>
							<h4>会社の目指</h4>
							<div class="text">わたしたちベースは常に一番近くで寄り添い様々な方の「CIS」となる</div>
						</div>
					</div>
					<div class="why-block"  data-aos="fade-right" data-aos-duration="500">
						<div class="inner-box">
							<div class="icon-box"><img src="/assets/images/resource/icon-5.svg" alt=""></div>
							<h4>ERPソリューション事業</h4>
							<div class="text">SAPを始め、最先端のグローバルソリューションを用いて、お客様のニーズにあわせた多様なサービスを提供します</div>
						</div>
					</div>
				   <div class="why-block" data-aos="fade-right" data-aos-duration="500">
					<div class="inner-box">
						<div class="icon-box"><img src="/assets/images/resource/icon-3.svg" alt=""></div>
						<h4>「CIS」に関わる全ての人</h4>
						<div class="text">「CIS」に関わる全ての人に「CIS」と仕事をして良かったと思って貰えるように</div>
					</div>
				   </div>
				</div>
			</div>

			<div class="image-col col-xl-6 col-lg-4 col-md-12 col-sm-12" data-aos="fade-right" data-aos-duration="500">
				<div class="inner">
					<div class="image-box"><img src="/assets/images/resource/why-image-1.svg" alt=""></div>
				</div>
			</div>

		</div>
	</div>
	<div class="floated-icon right"><img src="/assets/images/resource/floated-icon-right-2.svg" alt="" title=""></div>
</section>

<section class="about-two">
	<img src="/assets/images/shape/about-2-bg-1-1.png" class="about-two__bg-shape-1" alt="">
	<div class="container">
		<img src="/assets/images/shape/about-2-bg-1-2.png" class="about-two__bg-shape-2" alt="">
		<div class="row">
			<div class="col-lg-6">
				<div class="about-two__images wow fadeInLeft" data-wow-duration="1500ms" data-aos="fade-right" :data-aos-duration="500">
					<img src="/assets/images/resource/hero-1.png" class="float-bob-y" alt="">
				</div>
				<!-- /.about-two__images -->
			</div>
			<!-- /.col-lg-6 -->
			<div class="col-lg-6">
				<div class="about-two__content">
					<div class="block-title text-left" data-aos="fade-right" :data-aos-duration="500">
						<p><span>About Company</span></p>
						<h3>{{HomeInfo.CompanyName}}について</h3>
					</div>
					<!-- /.block-title text-left -->
					<p data-aos="fade-right" :data-aos-duration="500">弊社は「人材・信頼・共生・効率・創造」を礎に、未来に向けて優れたソリューションを持ち、 コンサルティングから運用・アウトソーシングまで、一流のサービスを提供する国際的情報サービスグループ企業を目指して参ります。
							<br/>
							当社のSES(システムエンジニアリングサービス)事業は、貴社の急な人手不足、イレギュラー対応が必要な短期的な作業にも、迅速にエンジニア/プログラマ等をご紹介致します。
							豊富な経験と高度なスキルを持つビジネスエンジニアが、お客様のプロジェクトを成功へと導くことをお手伝い致します。
							また、SES事業におけるパートナー企業様も積極的に募集しておりますので、ご興味のある企業様は、お気軽にご連絡下さいませ。
							<br />
							CISは、トータルソリューションのご提案から個別システム開発まで、幅広いニーズ・シーズにご対応が可能な少数精鋭のエンジニアチームが開発案件をお待ちしております。
							業務システムやWebサービス等の各種ソフトウェア開発について、要件定義、設計、プログラミング、テスト、運用の各工程に合わせたご要望を踏まえてハイスキルエンジニアが対応します。
					</p>

					<div class="about-two__counter-wrap">
						<div class="about-two__counter" data-aos="fade-right" :data-aos-duration="500">
							<a href="/about" class="octf-btn octf-btn-primary octf-btn-icon octf-btn-third home_img_right">&emsp; CIS概要&emsp;<i class="flaticon-right-arrow-1"></i>
							</a>
						</div>
						
					</div>
					<!-- /.about-two__counter-wrap -->
				</div>
				<!-- /.about-two__content -->
			</div>
			<!-- /.col-lg-6 -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container -->
</section>

<section class="hero_aria">
		<div class="container custom_container">
			<div class="row align-items-center">
			<div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
			     <p><span style="background-image: linear-gradient(90deg, #fff 0%, #f1ebeb 100%);">Join Us Now</span></p>					
				<h3 style="color: #fff;" data-aos="fade-right" :data-aos-duration="500">
				  社員と会社が共にある。
				  <br>活躍できる舞台がこにある。
				</h3>
			</div>
			<div class="col-lg-6">
				<div class="hero_content wow fadeInUp" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;color: #fff;">
					<h4 data-aos="fade-right" style="color: #fff;font-size:20px;line-height:40px" :data-aos-duration="500">システムエンジニアを目指す方を求めています。全学部・全学科からの募集で、社員の半数以上が文系出身、プログラミング未経験者です。ITのスペシャリストへ成長できる環境をご用意して、お待ちしています。
					<br/>&nbsp;
					</h4>
				    <a href="/recruit" class="octf-btn octf-btn-icon octf-btn-secondary">&emsp;Join Us Now&emsp;<i class="bi bi-arrow-right"></i></a>
				</div>
			</div>
			<div class="col-lg-6" data-aos="fade-right" :data-aos-duration="500">
				<div class="section_img wow fadeInLeft aos-init aos-animate inner" id="scene">
					<img data-depth="0.2" class="image-box" src="/assets/images/joinus.png" alt="">
				</div>
			</div>
		</div>
	</div>
</section>

<div class="professional-service-area service-area-design-agency pt-120 pb-96">
	<div class="container">
	   <div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
	      <p><span>What We Are Expert At</span></p><h3>サービス内容</h3>
	   </div>
		<div class="row mt-60">
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-1.png" alt="">
						<span>01</span>
					</div>
					<h3>開発・保守</h3>
					<p class="mt-4">お客様のご要望に応じた「OS、DB、言語、ツール、フレームワーク」によるソフトウエア開発・保守サービス。 汎用コンピュータの開発、コンバージョン、マイグレーションサービス。</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-2.png" alt="">
						<span>02</span>
					</div>
					<h3>CRMシステム</h3>
					<p class="mt-4">お客さまのご要望にきめ細かく対応する個別開発と、あらゆるお客さまに対応できるCRMパッケージの保守・運用のアウトソーシングサービスなど、お客様に最適なソリューションをご提供します。</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-3.png" alt="">
						<span>03</span>
					</div>
					<h3>WEBシステム</h3>
					<p class="mt-4">お客様のご要望に柔軟に対応する最適なシステムについて、各種携帯、ブラウザ等、各ベンダの端末に対応したフロントからバックエンドまでのWebシステムをご提供します。　　　　　　　　　</p>
				</div>
			</div>
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 container-wrap" data-aos="fade-right" :data-aos-duration="500">
				<div class="service-wrap service-wrap-agency overflow-hidden show">
					<div class="service-icon d-flex justify-content-between align-items-start">
						<img src="/assets/images/icon/service-4.png" alt="">
						<span>04</span>
					</div>
					<h3>オフショア開発</h3>
					<p class="mt-4">ブロックチェーン開発、CRMシステム開発、組込み系システム開発、コンサルティング、データ‐マイニング、OCR開発、ウェブ‐クローラーなど。&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</p>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="blog-area pt-120 pb-96">
	<div class="container">
		<div class="block-title text-center aos-init aos-animate" data-aos="fade-right" data-aos-duration="500">
	      <p><span>Our Blog Posts</span></p><h3>お知らせ</h3>
	    </div>
		<div class="row mt-60">
			<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-right" :data-aos-duration="500" v-for="(item, index) in newsList" :key="index">
				<div class="blog-wrap overflow-hidden blog-one__single">
					<div class="blog-img overflow-hidden">
						<router-link :to="`/news/${item.id}`"><img class="w-100" :src="item.img" alt="" style="width:370px;height:220px;"></router-link>
					</div>
					<div class="blog-content">
						<p class="blog-meta"><router-link :to="`/news/${item.id}`">CIS News</router-link> <span>|</span> {{ item.time }}</p>
						<div class="mt-3 mb-3" style="font-size: 16px;"><router-link :to="`/news/${item.id}`">{{ item.title }}</router-link></div>
						<div class="blog-btn">
							<router-link :to="`/news/${item.id}`" class="thm-btn blog-one__btn" style="padding: 1.5px 32.5px;">Read More</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<style scoped>
  .inner-box>div.icon-box>img {
	  filter: invert(51%) sepia(67%) saturate(3036%) hue-rotate(42deg) brightness(98%) contrast(412%);
  }
</style>

<script>
// banner設定
function slickDots (){
    if($('.slick-dots').length) {
        let slickList = $('.slick-dots')[0].childNodes;
        if (slickList.length) {
            var point = 1;
            $('#bannercroll').css('background-image','')
            for (var i=1; i <= slickList.length; i++) {
                point = i;
                const className = slickList[i-1].className;
                if(i==1 && className) { 
                    $('#bannercroll').css('background-image','')
                    $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
                else if(i==2 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%)');
                    // -webkit-radial-gradient(50% 50%, circle closest-side, rgba(11, 60, 175, 0.8) 0%, #0c44b3 100%);
                } else if(i==3 && className) {
                    $('#bannercroll').css('background-image','-webkit-radial-gradient(50% 50%, circle closest-side, rgba(47, 18, 137, 0.7) 0%, #4f1eab 100%)');
                } else {
                    //   $('#bannercroll').css('background-image','/images/first_banner.jpg');
                }
            }
        }
    }
}
window.setInterval(slickDots, 100);
import { onMounted, ref,onUnmounted } from "vue";
export default {
  name: "Home",
  setup() {
   
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      getNewsList();
      resize();
	   $('.owl-nav').hide();
    //   initSlick();
    });
    function resize() {
      const height = $(window).height();
      $('#bannercroll').css('height',height);
    }
    window.addEventListener('resize', resize)
    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>

