import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
let HomeInfo = {
    Tel: '03-5826-4669',
    Fax: '',
    PostCode: '110-0005',
    Email: 'sales@cis-jp.com',
    Address: '東京都台東区上野3丁目3-8',
    Building: 'ワイゼムビル4階A室',
    CompanyName: 'CIS株式会社',
    CompanyNo: 'CIS Co., Ltd.',
    Abbreviation: '（略称：CIS）',
    Representative: '叢　淑華',
    Katakana: 'ソウ　シュクカ',
    Capital: '500',
    Banks: 'abc<br/>zxzzx',
    CreateDate: '2021年05月13日',
    YearSell: '',
    HakenNo: '',
    Access: '',
    CTO: '叢 曉程',
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4284.98739823637!2d139.77020131573502!3d35.70361581175703!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188fdf195c71a9%3A0x520c0e9944631ae1!2zQ0lT5qCq5byP5Lya56S-!5e0!3m2!1szh-TW!2sjp!4v1695607962599!5m2!1szh-TW!2sjp'
}
const app = createApp(App)
    // load 
$.ajax({
    type: "GET",
    url: "/api/company.php",
    dataType: "json",
    success: (res) => {
        HomeInfo = res
        app.config.globalProperties.HomeInfo = res
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
    error: (res) => {
        app.config.globalProperties.HomeInfo = HomeInfo
        app.use(router).use(ElementUI).use(HomeInfo).use(AOS).mount('#app')
    },
});