<template>
  <!-- Start CIS breadcrumb section -->
<div class="main-banner inner-banner breadcrumb-area-about">
    <div class="image-layer" style="background-image: url(../images/background/banner-image-1.jpg);"></div>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="breadcrumb-content">
                    <div class="section-title white-2">
                        <h4>We’re the best</h4>
                        <h1 class="mt-3">会社情報</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 <!-- End CIS breadcrumb section -->
<section class="contact-info-area" id="contact-info-area">
   <br/>
      <div class="container" style='background-image: url("/assets/images/about-1.png"); background-repeat: no-repeat; background-position: right -21px center; background-size: 70%;'>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-two__info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-list-alt"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>会社名</h4>
                <p>{{HomeInfo.CompanyName}}{{HomeInfo.Abbreviation}}</p>
            </div>
            <!-- /.contact-info-details -->
        </div>
         <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-laptop" style="background-image: linear-gradient(-45deg, #61d8ff 0%, #6168ff 100%)!important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>会社名(英語表記)</h4>
                <p>{{HomeInfo.CompanyNo}}</p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-building" style="background-image:linear-gradient(-45deg, #ff90ca 0%, #d52176 100%)!important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>住所</h4>
                <p>
                  〒{{HomeInfo.PostCode}} {{HomeInfo.Address}} {{ HomeInfo.Building }}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-phone" style="background-image: linear-gradient(-45deg, rgb(64, 158, 255) 0%, rgb(255 64 197) 100%)!important;"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>代表電話番号</h4>
                <p>
                  <span v-if="HomeInfo.Fax" >TEL. </span><a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a>
                  <span v-if="HomeInfo.Fax" >&emsp;FAX. {{HomeInfo.Fax}}</span>
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-envelope-open" style="background-image:linear-gradient(-45deg, #1dc5ce 0%, rgb(235, 255, 64) 100%) !important;"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>E-mail</h4>
                <p>
                  <a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a>
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>

        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-user-circle" style="background-image: linear-gradient(-45deg, rgb(236, 16, 34) 0%, rgb(235, 255, 64) 100%)!important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>代表者名 (CEO)</h4>
                <p>
                  {{HomeInfo.Representative}}<span v-if="HomeInfo.Katakana">（{{HomeInfo.Katakana}}）</span>
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center" v-if="HomeInfo.CTO!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-users" style="background-image:linear-gradient(-45deg, rgb(220, 136, 255) 0%, rgb(64, 158, 255) 100%)!important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>取締役</h4>
                <p>
                  {{HomeInfo.CTO}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-jpy" style="background-image:linear-gradient(-45deg, rgb(209 193 11) 0%, rgb(235, 255, 64) 100%) !important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>資本金</h4>
                <p>
                  {{formatPrice(this.HomeInfo.Capital)}}万円
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-calendar-check-o" style="background-image:linear-gradient(-45deg, rgb(230 35 67) 0%, rgb(232 120 81) 100%) !important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>設立</h4>
                <p>
                  {{HomeInfo.CreateDate}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center"  v-if="HomeInfo.Employees!='' && HomeInfo.Employees > 0">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-address-card" style="background-image:linear-gradient(90deg, #3a8ee6 0%, #409eff 51%, #8abc65 100%)!important"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" >
                <h4>従業員(人数)</h4>
                <p>
                  {{HomeInfo.Employees}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center"  v-if="HomeInfo.Banks!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-university" style="background-image:linear-gradient(-45deg, rgb(230 199 35) 0%, rgb(232, 120, 81) 100%) !important;"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>主要取引銀行</h4>
                <p>
                  {{HomeInfo.Banks}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center" v-if="HomeInfo.YearSell!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-line-chart" style="background-image:linear-gradient(90deg, #67c23a 0%, #8abc65 51%, #d9ecff 100%)!important;"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>売上</h4>
                <p>
                  {{HomeInfo.YearSell}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
        <div class="contact-info d-flex align-items-center" v-if="HomeInfo.HakenNo!=''">
            <div class="contact-info-icon" data-aos="fade-right" :data-aos-duration="500">
                <i class="fa fa-gift"></i>
            </div>
            <!-- /.contact-info-icon -->
            <div class="contact-info-details" data-aos="fade-right" :data-aos-duration="500" style="margin-top:10px;">
                <h4>労働者派遣事業許可番号</h4>
                <p>
                  {{HomeInfo.HakenNo}}
                </p>
            </div>
            <!-- /.contact-info-details -->
        </div>
      </div>
</section>
</template>

<style>
#contact-info-area h4{
    line-height: 50px;
}
#contact-info-area .contact-info-icon i {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    color: #f8f9fa;
    border-radius: 50%;
    margin-right: 30px;
    position: relative;
    box-shadow: 0px 20px 30px 0px #b3d8ff;
    top: 5px;
    background-image: linear-gradient(90deg, #0575bb 0%, #66b1ff 51%, #d9ecff 100%)!important;
}
#contact-info-area .align-items-center {
    align-items: center!important;
    margin-bottom: 20px;
}
#contact-info-area a {
    font-family: "Open Sans", sans-serif;
    color: #8abc65;
    background-image: linear-gradient(90deg, #8abc65 0%, #0d9e7c 100%);
    -webkit-background-clip: text;
    font-size: 16px;
}
#contact-info-area p {
    font-size: 16px;
    color: #8abc65;
    background-image: linear-gradient(90deg, #8abc65 0%, #0d9e7c 100%);
    -webkit-background-clip: text;
}
</style>
<script>
import {ref} from "vue";
import BreadCrumb from "../components/BreadCrumb";
export default {
  name: "About",
  components:{ BreadCrumb },
  setup() {
      const HomeInfo = ref({})
      $.ajax({
        type: "GET",
        url: "/api/company.php",
        dataType: "json",
        // crossDomain: true,
        beforeSend: function(xhr){
             xhr.withCredentials = true;
             xhr.setRequestHeader('Access-Control-Allow-Origin','*');
             xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
        },
        async: false,
        success: (res) => {
            HomeInfo.value = res;
        },
        error: (res) => {
           HomeInfo.value =   {
                Tel: '03-5826-4669',
                Fax: '',
                PostCode: '110-0005',
                Email: 'sales@cis-jp.com',
                Address: '東京都台東区上野3丁目3-8',
                Building: 'ワイゼムビル4階A室',
                CompanyName: 'CIS株式会社',
                CompanyNo: 'CIS Co., Ltd.',
                Abbreviation: '（略称：CIS）',
                Representative: '叢　淑華',
                Katakana: 'ソウ　シュクカ',
                Capital: '500',
                Banks: '',
                CreateDate: '2021年05月13日',
                YearSell: '',
                HakenNo: '',
                Employees:'',
                CTO: '叢 曉程',
                MapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4284.98739823637!2d139.77020131573502!3d35.70361581175703!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188fdf195c71a9%3A0x520c0e9944631ae1!2zQ0lT5qCq5byP5Lya56S-!5e0!3m2!1szh-TW!2sjp!4v1695607962599!5m2!1szh-TW!2sjp'
            }
        }
     })
     return {HomeInfo}
  },
  methods: {
    formatPrice(value) {
        return (value||'1000').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
